<template>
  <div
    class="w-full absolute top-24 md:relative lg:w-2/3 2xl:w-3/4 bg-white pt-6 md:pt-0 pb-5 px-5 flex flex-col"
  >
    <DownloadCenterCard
      v-for="(download, pd) in downloads"
      :key="pd"
      :download="download"
    />
    <DownloadCenterCardEmpty
      v-if="downloads.length == 0"
      :category="category"
      :type="type"
    />
  </div>
</template>
<script>
import DownloadCenterCard from './DownloadCenterCard'
import DownloadCenterCardEmpty from './DownloadCenterCardEmpty'
export default {
  components: {
    DownloadCenterCard,
    DownloadCenterCardEmpty
  },
  data() {
    return {}
  },
  mounted() {},
  props: {
    downloads: {
      type: Array,
      default: () => {
        return []
      }
    },
    labels: {
      type: Object
    },
    category: {
      type: Object
    },
    type: {
      type: Object
    },
    query: {
      type: String
    }
  }
}
</script>
