<template>
  <li class="list-none">
    <div
      class="flex justify-between items-center pl-5 pr-5 py-5 border-b border-gray-200 bg-white"
    >
      <span
        class="h-10 inline-flex items-center text-lg font-body font-thin text-black"
      >
        {{ data.name }}
      </span>
      <button
        class="w-10 h-10 text-black inline-flex items-center justify-end"
        @click="toggleMenu"
        v-if="hasChildren"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
    <div
      v-if="isOpen && hasChildren"
      class="overflow-y-scroll fixed top-12 md:top-0 bottom-0 inset-x-0 z-50 bg-white pt-20"
    >
      <div
        class="w-full fixed top-12 md:top-0 p-5 flex justify-between items-center border-b border-gray-200 bg-white"
      >
        <button @click="close" class="text-black pr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-width="1.5" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <span
          class="uppercase text-black font-display font-semibold text-xl truncate"
        >
          {{ data.name }}
        </span>
        <button @click="closeMenu" class="text-black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-10 h-10"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-width="1.25" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <ul>
        <LinkItem v-for="item in data.children" :key="item" :data="item" />
      </ul>
    </div>
  </li>
</template>
<script>
import LinkItem from './NewMobileNavigationLinkItem.vue'

export default {
  name: 'MobileNavigationGroupItem',
  data() {
    return {
      isOpen: false,
      hasChildren: false
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created() {
    if ('children' in this.data) {
      this.hasChildren = true
    }
  },
  components: {
    LinkItem
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen
    },
    close() {
      this.isOpen = false
    },
    closeMenu() {
      this.$emit('close-menu')
    }
  }
}
</script>
