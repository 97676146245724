<template>
  <div
    :class="{
      'flex items-center gap-10 w-1/2': cat == 48,
      'w-1/3': cat != 48
    }"
  >
    <div class="w-full max-w-2xl" :class="{ 'w-full': cat != 48 }">
      <div class="flex items-center justify-between py-2 bg-white">
        <a
          v-if="data.url"
          :href="data.url"
          class="inline-flex items-center h-10 font-bold text-black uppercase font-display hover:text-orange-500 word-wrap"
        >
          {{ data.name }}
        </a>
        <span
          v-else
          class="inline-flex items-center h-10 font-bold text-black uppercase font-display word-wrap"
        >
          {{ data.name }}
        </span>
      </div>
      <ul v-if="hasChildren">
        <LinkItem
          v-for="item in data.children"
          :key="item"
          :data="item"
          @changeImageUrl="changeImageUrl"
        />
      </ul>
    </div>
    <div class="hidden 2xl:block" v-if="cat == 48">
      <img :src="image" class="mt-20" />
    </div>
  </div>
</template>
<script>
import LinkItem from './DesktopNavigationLinkItem.vue'

export default {
  name: 'MobileNavigationGroupItem',
  data() {
    return {
      isOpen: false,
      hasChildren: false,
      image: 'https://placehold.it/600x400'
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cat: {
      type: Number
    }
  },
  created() {
    if ('children' in this.data) {
      this.hasChildren = true
      this.image = this.data.children[0].image
    }
  },
  components: {
    LinkItem
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen
    },
    changeImageUrl(url) {
      this.image = url
    }
  }
}
</script>
