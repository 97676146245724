<template>
  <button class="text-orange-500 w-10 h-10 inline-block">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      v-if="!isOpen"
    >
      <path stroke-width="1.5" d="M4 6h16M4 12h16M4 18h16" />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      v-if="isOpen"
    >
      <path stroke-width="1.5" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>
