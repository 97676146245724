<template>
  <div
    class="fixed top-32 z-40 w-full block md:hidden bg-white border-b border-gray-200 py-4 px-5"
  >
    <!-- small filter -->
    <div>
      <div class="relative">
        <input
          type="text"
          placeholder="Suchen"
          v-model="localSearchQuery"
          autocomplete="off"
          name="search"
          class="relative w-full py-3 pl-3 pr-10 mb-4 text-left transition duration-150 ease-in-out bg-white border border-gray-300 cursor-default focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 focus:border-orange-500"
          @focus="showSuggestions"
          @blur="leaveSearchFocus"
          @keyup.enter="$emit('search', localSearchQuery)"
          @keyup.esc="hideSuggestions"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="absolute w-4 h-4 right-0 top-0 mt-4 mr-3 text-orange-500"
          @click="localSearchQuery = ''"
          v-if="localSearchQuery != ''"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
        <div
          v-if="suggestions.length > 0 && suggestionsVisible"
          class="mt-6 w-full bg-white border absolute top-6 z-50 flex flex-col divide-y divide-gray-400"
        >
          <div
            v-for="suggestion in suggestions"
            :key="suggestion.keyword"
            class="py-2 px-4 font-body font-thin focused:bg-orange-500 focused:text-white"
            @click="chooseSuggestion(suggestion.keyword)"
          >
            {{ suggestion.keyword }}
          </div>
        </div>
      </div>
      <div v-if="Object.keys(allOptions).length > 0" class="flex w-full">
        <!--{{ allOptions }}-->
        <MobileOptionSelect
          type="types"
          :data="allOptions.types"
          @type="setType"
          class="shrink w-full max-w-full"
        />
        <button
          class="ml-4 block border border-gray-300 p-3.5"
          @click="$emit('full-filter')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import MobileOptionSelect from './MobileProductFilterOptionSelectCompact.vue'
export default {
  data() {
    return {
      localSearchQuery: '',
      suggestions: []
    }
  },
  props: {
    lang: {
      default: 99,
      type: Number
    },
    allOptions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectedOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    labels: {
      type: Object,
      default: () => {
        return {}
      }
    },
    searchQuery: {
      type: String,
      default: ''
    }
  },
  components: {
    MobileOptionSelect
  },

  methods: {
    showSuggestions() {
      this.suggestionsVisible = true
    },
    hideSuggestions() {
      this.suggestionsVisible = false
    },
    leaveSearchFocus() {},
    setType(type) {
      this.$emit('type', type)
    },
    fetchAutosuggestions() {
      this.loading = true
      const query =
        'https://benedict-and-riva.com/?rex-api-call=product_search_suggestions&query=' +
        this.localSearchQuery
      axios
        .get(query)
        .then(response => {
          console.log(response.data)
          this.suggestions = response.data.data
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    chooseSuggestion(keyword) {
      this.suggestions = []
      this.localSearchQuery = keyword
      this.$emit('search', keyword)
    },
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        window.location.pathname +
          '?' +
          Object.keys(params)
            .map(key => {
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
              )
            })
            .join('&')
      )
    }
  },
  watch: {
    localSearchQuery: function(value) {
      if (this.localSearchQuery === '') {
        this.suggestions = []
      }
      if (this.localSearchQuery.length >= 3) {
        console.log(value)
        this.fetchAutosuggestions()
      }
    }
  }
}
</script>
