<template>
  <div>
    <div
      class="border-b border-gray-200 fixed overflow-scroll top-12 inset-x-0 z-50 w-full flex md:hidden bg-white py-2 px-4"
    >
      <div
        class="w-full text-lg font-display font-bold uppercase py-2 flex items-center"
      >
        <span class="">Download Center</span>
      </div>
      <button
        class="ml-4 block border border-gray-300 p-4"
        @click="open = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div
      class="fixed overflow-scroll pt-20 top-12 inset-0 z-50 w-full block md:hidden bg-white"
      :class="open ? 'block' : 'hidden'"
    >
      <div class="fixed top-12 inset-x-0 z-50 bg-white">
        <!-- big filter -->
        <div class="flex border-b border-gray-200 divide-x divide-gray-200">
          <div
            class="w-full text-xl font-display font-bold uppercase py-4 px-4 flex items-center"
          >
            <span class="">Filter anzeigen</span>
          </div>
          <button
            class="block border-l border-gray-200 py-4 px-4"
            @click="close()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-width="1" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
      <div class="px-4">
        <!-- sticky top-18 -->
        <h1 class="uppercase font-display font-semibold text-2xl">
          {{ labels.title }}
        </h1>
        <p class="font-body font-thin text-lg">
          {{ labels.subline }}
        </p>
        <p class="font-body font-thin text-sm mt-2">
          {{ labels.legalnote }}
          <a :href="labels.legalnote_url" class="text-orange-500">{{
            labels.legalnote_label
          }}</a
          >{{ labels.legalnote_label_after }}.
        </p>
      </div>

      <div class="flex flex-col sticky mt-5 top-12 px-4">
        <div v-for="(options, type) in allOptions" :key="type">
          <OptionSelect
            :type="type"
            :data="options"
            @filter="setFilter"
            @filter-category="reloadCategories"
            @filter-type="reloadTypes"
            @category="setCategory"
            @type="setType"
          />
        </div>
        <button
          @click="close()"
          class="bg-orange-500 text-white font-display font-medium text-center text uppercase py-3 my-4"
        >
          Filter anwenden
        </button>
        <button
          @click="reset()"
          class="bg-gray-300 text-white font-display font-medium text-center text uppercase py-3 my-4"
        >
          {{ labels.reset }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
// import { ref } from 'vue'
import OptionSelect from './DownloadCenterOptionSelect.vue'
export default {
  data() {
    return {
      allOptions: [],
      selectedOptions: [],
      labels: [],
      open: false
    }
  },
  props: {
    lang: {
      default: 99,
      type: Number
    }
  },
  components: {
    OptionSelect
  },
  mounted() {
    axios
      .get(
        'https://benedict-and-riva.com/?rex-api-call=download_center_options&lang=' +
          this.lang
      )
      .then(response => {
        this.allOptions = response.data.options
        this.labels = response.data.labels
      })
      .catch(error => {
        console.log(error)
      })

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    console.log(urlParams)

    // if (urlParams.has('category')) {
    //   const f = 'categories:' + urlParams.get('category')
    //   this.reloadTypes(f)
    // } else {
    this.loadProducts()
    // }
  },
  methods: {
    setFilter(value) {
      const arr = value.split(':')
      const type = arr[0]
      const list = arr[1]
      this.selectedOptions[type] = list
      this.loadProducts()
    },
    reloadCategories(value) {
      const arr = value.split(':')
      const type = arr[0]
      const list = arr[1]

      //  var typesOptions = ref(this.allOptions.products.options)

      // here load new

      axios
        .get(
          'https://benedict-and-riva.com/?rex-api-call=download_center_options&categories=' +
            list +
            '&lang=' +
            this.lang
        )
        .then(response => {
          //this.allOptions = null
          //typesOptions = response.data.types.options

          this.allOptions.types.options.splice(0)
          this.allOptions.types.options.push(
            ...response.data.options.types.options
          )

          this.allOptions.products.options.splice(0)
          this.allOptions.products.options.push(
            ...response.data.options.products.options
          )
        })
        .catch(error => {
          console.log(error)
        })

      this.selectedOptions[type] = list
      this.loadProducts()
    },
    reloadTypes(value) {
      const arr = value.split(':')
      const type = arr[0]
      const list = arr[1]

      // var typesOptions = ref(this.allOptions.products.options)

      // here load new

      var query =
        'https://benedict-and-riva.com/?rex-api-call=download_center_options&types=' +
        list +
        '&lang=' +
        this.lang

      console.log(query)

      axios
        .get(query)
        .then(response => {
          //this.allOptions = null
          // console.log(typesOptions)
          // console.log(response.data.options.products.options)
          //typesOptions = response.data.types.options

          this.allOptions.types.options.splice(0)
          this.allOptions.types.options.push(
            ...response.data.options.types.options
          )

          this.allOptions.products.options.splice(0)
          this.allOptions.products.options.push(
            ...response.data.options.products.options
          )
        })
        .catch(error => {
          console.log(error)
        })

      this.selectedOptions[type] = list

      console.log(this.selectedOptions)

      this.loadProducts()
    },
    reset() {
      window.location =
        'https://' + window.location.hostname + window.location.pathname
      this.$emit('reset', Date.now())
    },
    close() {
      this.open = false
    },
    buildQueryString() {
      var queryList = []

      for (var prop in this.selectedOptions) {
        const queryString = prop + '=' + this.selectedOptions[prop]
        if (this.selectedOptions[prop] != '') {
          queryList.push(queryString)
        }
      }
      return queryList.join('&')
    },
    loadProducts() {
      // build query string
      const queryString = this.buildQueryString()
      this.$emit('filter-query', queryString)
      const query =
        'https://benedict-and-riva.com/?rex-api-call=download_center&' +
        queryString +
        '&lang=' +
        this.lang

      axios
        .get(query)
        .then(response => {
          this.$emit('refresh', response.data.downloads)
        })
        .catch(error => {
          console.log(error)
        })
    },
    setCategory(value) {
      this.$emit('category', value)
    },
    setType(value) {
      this.$emit('type', value)
    }
  }
}
</script>
