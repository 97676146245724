<template>
  <div class="md:flex items-stretch min-h-screen">
    <Navigation
      v-if="lang"
      :lang="lang"
      :key="nav"
      :all-options="allOptions"
      :selected-options="selectedOptions"
      :labels="labels"
      @reset="resetAll"
      @loadProducts="loadProducts"
      @reloadTypes="reloadTypes"
      @category="setCategory"
      @type="setType"
      @filter-query="setQuery"
      @labels="makeLabels"
      @search="searchProducts"
    />
    <Results
      v-if="lang"
      :products="products"
      :category="category"
      :type="type"
      :query="query"
      :key="nav"
      :labels="productLabels"
      :loading="loading"
    />
  </div>
</template>
<script>
import axios from 'axios'
import { ref } from 'vue'
import Navigation from './components/ProductFilterNavigation.vue'
import Results from './components/ProductFilterResults.vue'

export default {
  name: 'ProductFilter',
  data() {
    return {
      products: [],
      lang: undefined,
      nav: 0,
      category: {},
      type: {},
      query: '',
      labels: {},
      loading: false,
      allOptions: {},
      selectedOptions: {}
    }
  },
  mounted() {
    this.setLanguage()
    // load filter options
    this.loadFilterOptions().then(() => {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const categories = urlParams.get('categories')
      const types = urlParams.get('types')
      console.log(this.allOptions.categories.options)
      var category = this.allOptions.categories.options.find(
        x => x.id == categories
      )
      var type = this.allOptions.types.options.find(x => x.id == types)
      if (category) {
        this.setCategory(category)
        this.reloadTypes('categories:' + categories)
      }
      if (type) {
        this.setType(type)
        console.log('!!!!! ', type)
        this.loadProducts({ types: type.id })
      } else {
        this.loadProducts()
      }
    })
  },
  methods: {
    refreshProducts(data) {
      this.products = null
      this.products = data
    },
    buildQueryString() {
      var queryList = []

      console.log('selected: ', this.selectedOptions)

      for (var prop in this.selectedOptions) {
        const queryString = prop + '=' + this.selectedOptions[prop]
        if (this.selectedOptions[prop] != '') {
          queryList.push(queryString)
        }
      }
      console.log('selected: ', this.selectedOptions)
      console.log(queryList)
      return queryList.join('&')
    },
    loadProducts(options) {
      this.loading = true
      this.type = {}
      if (options) {
        console.log('options', options)
        for (var prop in options) {
          this.selectedOptions[prop] = options[prop]
          // this.$set(this.selectedOptions, prop, options[prop])
        }
        console.log('selection: ', this.selectedOptions)
      }
      // build query string
      const queryString = this.buildQueryString()
      this.setQuery(queryString)
      const query =
        'https://benedict-and-riva.com/?rex-api-call=product_filter&' +
        queryString +
        '&lang=' +
        this.lang

      axios
        .get(query)
        .then(response => {
          console.log('products fetched')
          console.log(response.data.products)
          this.productLabels = response.data.labels
          this.products = null
          this.products = response.data.products
          this.loading = false
          console.log(this.labels)
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })
    },
    searchProducts(queryString) {
      this.loading = true
      const query =
        'https://benedict-and-riva.com/?rex-api-call=product_search&search=' +
        queryString +
        '&lang=' +
        this.lang

      axios
        .get(query)
        .then(response => {
          window.scrollTo(0, 0)
          this.productLabels = response.data.labels
          this.products = response.data.products
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    reloadTypes(value) {
      const arr = value.split(':')
      const type = arr[0]
      const list = arr[1]

      var typesOptions = ref(this.allOptions.types.options)

      // here load new

      axios
        .get(
          'https://benedict-and-riva.com/?rex-api-call=product_filter_options&categories=' +
            list +
            '&lang=' +
            this.lang
        )
        .then(response => {
          //this.allOptions = null
          console.log(typesOptions)
          console.log(response.data.options.types.options)
          //typesOptions = response.data.types.options
          this.allOptions.types.options.splice(0)
          this.allOptions.types.options.push(
            ...response.data.options.types.options
          )
        })
        .catch(error => {
          console.log(error)
        })

      this.selectedOptions[type] = list
    },
    async loadFilterOptions() {
      await axios
        .get(
          'https://benedict-and-riva.com/?rex-api-call=product_filter_options&lang=' +
            this.lang
        )
        .then(response => {
          this.allOptions = response.data.options
          this.labels = response.data.labels
          console.log('Mounted')
        })
        .catch(error => {
          console.log(error)
        })

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      console.log(urlParams)
    },
    resetAll(value) {
      this.nav = value
      this.type = {}
      this.category = {}
    },
    setCategory(value) {
      this.category = value
    },
    setType(value) {
      this.type = value
    },
    setQuery(value) {
      this.query = value
    },
    makeLabels(value) {
      this.labels = value
    },
    setLanguage() {
      var lang = localStorage.getItem('lang')
      if (lang === null) {
        lang = 2
      }
      this.lang = lang
    }
  },
  components: {
    Navigation,
    Results
  }
}
</script>
