<template>
  <div class="md:flex items-stretch min-h-screen">
    <DownloadCenterNavigation
      v-if="lang"
      :lang="lang"
      @refresh="refreshDownloads"
      @reset="resetAll"
      :key="nav"
      @category="setCategory"
      @type="setType"
      @filter-query="setQuery"
      @labels="makeLabels"
    />
    <MobileDownloadCenterNavigation
      v-if="lang"
      :lang="lang"
      @refresh="refreshDownloads"
      @reset="resetAll"
      :key="nav"
      @category="setCategory"
      @type="setType"
      @filter-query="setQuery"
      @labels="makeLabels"
    />
    <DownloadCenterResults
      v-if="lang"
      :downloads="downloads"
      :category="category"
      :type="type"
      :query="query"
      :key="nav"
      :labels="labels"
    />
  </div>
</template>
<script>
import DownloadCenterNavigation from './components/DownloadCenterNavigation.vue'
import MobileDownloadCenterNavigation from './components/MobileDownloadCenterNavigation.vue'
import DownloadCenterResults from './components/DownloadCenterResults.vue'

export default {
  name: 'DownloadCenter',
  data() {
    return {
      downloads: [],
      lang: undefined,
      nav: 0,
      category: {},
      type: {},
      query: '',
      labels: {}
    }
  },
  mounted() {
    console.log(this.type)
    var lang = localStorage.getItem('lang')
    if (lang === null) {
      lang = 2
    }
    this.lang = lang
  },
  methods: {
    refreshDownloads(data) {
      this.downloads = null
      this.downloads = data
    },
    resetAll(value) {
      this.nav = value
      this.type = {}
      this.category = {}
    },
    setCategory(value) {
      this.category = value
    },
    setType(value) {
      this.type = value
    },
    setQuery(value) {
      this.query = value
    },
    makeLabels(value) {
      this.labels = value
    }
  },
  components: {
    DownloadCenterNavigation,
    DownloadCenterResults,
    MobileDownloadCenterNavigation
  }
}
</script>
