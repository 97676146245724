<template>
  <li class="list-none" :class="{ 'text-orange-500': isOpen }">
    <div class="flex items-center justify-between">
      <a
        v-if="data.id != 48"
        :href="data.url"
        class="text-lg uppercase font-display"
        >{{ data.name }}</a
      >
      <span
        v-else
        class="text-lg uppercase cursor-pointer font-display"
        @click="toggleMenu"
        >{{ data.name }}</span
      >
      <button
        class="w-8 h-8 ml-2 text-orange-500"
        @click="toggleMenu"
        v-if="hasChildren"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          v-if="!isOpen"
        >
          <path stroke-width="1" d="M19 9l-7 7-7-7" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          v-if="isOpen"
        >
          <path stroke-width="1" d="M5 15l7-7 7 7" />
        </svg>
      </button>
    </div>
    <!-- -left-10 -->
    <div
      class="absolute inset-x-0 w-full px-20 py-10 bg-white shadow top-20"
      v-if="isOpen && hasChildren"
    >
      <div
        v-if="data.id == 48"
        class="flex items-start justify-between gap-x-10"
      >
        <Group
          v-for="(group, count) in data.children"
          :key="count"
          :data="group"
          :count="count"
          :cat="data.id"
          class=""
        />
        <div class="w-2/3 max-w-3xl">
          <div class="flex items-center justify-between py-2 bg-white">
            <a
              :href="data.landingpages[0].url"
              class="inline-flex items-center h-10 font-bold text-black uppercase break-words font-display hover:text-orange-500 word-wrap"
            >
              {{ data.landingpages[0].name }}
            </a>
          </div>
          <div
            class="grid grid-cols-2 grid-rows-3 gap-8 grid-overflow-wrap gap-y-6"
          >
            <a
              v-for="(lp, count) in data.landingpages[0].children"
              :key="count"
              :href="lp.url"
              class="flex items-center gap-4 font-thin text-black font-body"
            >
              <div v-if="count < 6" class="flex-shrink-0 w-48">
                <img :src="lp.image" :alt="lp.name" class="w-full" />
              </div>
              <div class="inline">{{ lp.name }}</div>
            </a>
          </div>
        </div>
      </div>
      <div v-else class="flex gap-10">
        <Group
          v-for="(group, count) in data.children"
          :key="count"
          :data="group"
          :count="count"
          :cat="data.id"
          class=""
        />
      </div>
    </div>
  </li>
</template>
<script>
import Group from './DesktopNavigationGroupItem.vue'
export default {
  name: 'MobileNavigationRootItem',
  data() {
    return {
      hasChildren: false
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    count: {
      type: Number,
      default: 0
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if ('children' in this.data) {
      this.hasChildren = true
    }
  },
  methods: {
    toggleMenu() {
      // this.isOpen = !this.isOpen
      this.$emit('toggled', this.isOpen)
      this.$emit('toggled2', this.count)
    }
  },
  components: { Group }
}
</script>
