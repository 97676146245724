<template>
  <li class="list-none">
    <a
      :href="data.url"
      class="inline-flex items-center justify-between inline-block w-full px-2 py-4 font-thin text-black bg-white border-b border-black font-body"
      @mouseenter="emitImageUrl()"
    >
      {{ data.name }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20.381 20"
        class="w-4 h-4 text-orange-500"
      >
        <g fill="none">
          <g stroke="currentColor">
            <path d="M1.115 10.018h18.337M11.65 2l8.022 8.022-8.022 8.022" />
          </g>
          <path d="M0 0h20v20H0z" />
        </g>
      </svg>
    </a>
  </li>
</template>
<script>
export default {
  name: 'MobileNavigationLinkItem',
  data() {
    return {
      isOpen: false,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    emitImageUrl() {
      this.$emit('change-image-url', this.data.image)
      console.log(this.data.image)
    },
  },
}
</script>
