<template>
  <div class="relative w-full bg-white pb-5 px-5">
    <div v-if="loading" class="">
      <div
        type="button"
        class="mt-4 bg-gray-50 py-12 w-full mx-auto inline-flex items-center justify-center transition ease-in-out duration-150 font-body font-thin"
        disabled=""
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Produkte werden geladen
      </div>
    </div>
    <div v-else>
      <div class="flex md:hidden space-y-8 flex-col pt-4 sticky top-32">
        <ProductCard
          v-for="(product, pd) in products"
          :key="pd"
          :product="product"
          :properties="product_props"
          :type="type"
          :query="query"
          :labels="labels"
        />
      </div>
      <EmptyCard
        v-if="products.length == 0"
        :category="category"
        :type="type"
      />
    </div>
  </div>
</template>
<script>
import ProductCard from './MobileProductFilterCard'
import EmptyCard from './MobileProductFilterCardEmpty'
export default {
  components: {
    ProductCard,
    EmptyCard
  },
  data() {
    return {
      product_props: []
    }
  },
  mounted() {},
  props: {
    products: {
      type: Array,
      default: () => {
        return []
      }
    },
    labels: {
      type: Object
    },
    category: {
      type: Object
    },
    type: {
      type: Object
    },
    query: {
      type: String
    },
    loading: {
      type: Boolean
    }
  }
}
</script>
