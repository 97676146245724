<template>
  <div class="">
    <div
      class="fixed top-0 z-50 w-full nav-top "
      id="pageHeader"
      :class="{ 'bg-white text-black': isOpen, 'is-open': isOpen }"
    >
      <div
        class="flex items-center justify-between px-4 py-2 bg-gray-100 md:hidden font-body"
      >
        <div class="inline-flex items-center font-medium text-gray-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4 text-orange-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
            />
          </svg>
          <a
            href="tel:+4934187099810"
            @click="gtag('Tel-Link', 'Tel-Link')"
            class="py-2 ml-2 text-xs no-underline"
            >+49 341 / 870 998-10</a
          >
        </div>
        <div class="inline-flex items-center font-medium text-gray-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4 text-orange-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
            />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <a
            href="mailto:info@benedict-and-riva.com"
            @click="gtag('Mailto Link', 'Mailto Link')"
            class="py-2 ml-2 text-xs "
            >info@benedict-and-riva.com</a
          >
        </div>
      </div>
      <div
        class="flex flex-row items-center justify-between h-20 px-5 py-5 transition duration-500 ease-in-out bg-transparent border-b border-transparent"
      >
        <div class="inline-block">
          <a href="/">
            <Logo class="w-32 lg:w-48" :class="{ 'text-black': isOpen }" />
          </a>
        </div>
        <DesktopNavigation
          class="hidden xl:block"
          :class="{ 'text-black': isOpen }"
          :lang="lang"
          @toggled="toggledNav"
          v-if="lang"
        />

        <!-- Lang Switch -->

        <div class="flex items-center">
          <LangSwitch
            v-if="lang && article"
            :article="article"
            :lang="lang"
            class="ml-8 mr-4"
          />
          <MobileTrigger
            :isOpen="isMobileOpen"
            @click="toggleMenu"
            class="xl:invisible"
          />
        </div>
      </div>
    </div>
    <!-- <MobileNavigation
      class="fixed z-50 w-full top-32 min-h-20 xl:hidden"
      :lang="lang"
      v-if="isMobileOpen && lang"
    /> -->
    <MobileNavigation
      class="fixed inset-x-0 bottom-0 z-50 w-full overflow-y-scroll top-32 md:top-20 min-h-20 xl:hidden"
      :lang="lang"
      v-if="isMobileOpen && lang"
      @close-menu="isMobileOpen = false"
    />
    <div
      class="fixed inset-x-0 bottom-0 z-40 bg-black bg-opacity-50 top-32"
      v-if="isOpen"
    ></div>
    <div
      class="fixed inset-x-0 bottom-0 z-40 bg-black bg-opacity-50 top-32"
      v-if="isMobileOpen"
    ></div>
  </div>
</template>

<script>
import DesktopNavigation from './components/DesktopNavigation.vue'
// import MobileNavigation from './components/MobileNavigation.vue'
import MobileNavigation from './components/NewMobileNavigation.vue'
import MobileTrigger from './components/MobileTrigger.vue'
import LangSwitch from './components/LangSwitch.vue'
import Logo from './components/Logo.vue'

export default {
  name: 'Navigation',
  data() {
    return {
      isOpen: false,
      isMobileOpen: false,
      lang: undefined,
      article: undefined
    }
  },
  components: {
    DesktopNavigation,
    MobileNavigation,
    MobileTrigger,
    LangSwitch,
    Logo
  },
  mounted() {
    var lang = localStorage.getItem('lang')
    if (lang === null) {
      lang = 2
    }
    this.lang = lang

    var article = localStorage.getItem('article')
    if (article === null) {
      article = 4
    }
    this.article = article
  },
  methods: {
    toggledNav(value) {
      this.isOpen = value
    },
    toggleMenu() {
      this.isMobileOpen = !this.isMobileOpen
    },
    gtag(cat, label) {
      this.$gtag.event('Click', {
        'event_category' : cat,
        'event_label' : label
      })
    }
  }
}
</script>
