<template>
  <div class="">
    <CompactFilter
      v-if="!fullFilterOpen"
      :all-options="allOptions"
      :selected-options="selectedOptions"
      :labels="labels"
      :search-query="searchQuery"
      @search="search"
      @fullFilter="openFullFilter()"
      @type="loadProductsByType"
    />

    <FullFilter
      v-if="fullFilterOpen"
      :all-options="allOptions"
      :selected-options="selectedOptions"
      :labels="labels"
      :search-query="searchQuery"
      @search="search"
      @fullFilter="closeFullFilter()"
      @loadProducts="loadProducts"
      @reloadTypes="reloadTypes"
      @category="setCategory"
    />
  </div>
</template>
<script>
import CompactFilter from './MobileProductFilterNavigationCompact.vue'
import FullFilter from './MobileProductFilterNavigationFull.vue'
export default {
  data() {
    return {
      searchQuery: '',
      fullFilterOpen: false
    }
  },
  props: {
    lang: {
      default: 99,
      type: Number
    },
    allOptions: {
      type: Object
    },
    selectedOptions: {
      type: Object
    },
    labels: {
      type: Object
    }
  },
  components: {
    CompactFilter,
    FullFilter
  },
  mounted() {},
  methods: {
    loadProductsByType(type) {
      this.$emit('type', type)
      this.$emit('load-products', { types: type.id })
    },
    setCategory(category) {
      console.log('nav', category)
      this.$emit('category', category)
    },
    loadProducts(options) {
      this.$emit('load-products', options)
    },
    reset() {
      window.location =
        'https://' + window.location.hostname + window.location.pathname
      this.$emit('reset', Date.now())
    },
    openFullFilter() {
      this.fullFilterOpen = true
      console.log('fullFilterOpen')
    },
    closeFullFilter() {
      this.fullFilterOpen = false
      console.log('fullFilterClosed')
    },
    search(queryString) {
      this.$emit('search', queryString)
    },
    reloadTypes(value) {
      this.$emit('reload-types', value)
    }
  }
}
</script>
