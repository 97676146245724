<template>
  <div
    class="w-full bg-gray-100 flex flex-col gap-5 items-center py-20 mb-4 sticky top-20"
  >
    <h2 class="font-display text-xl font-medium uppercase text-center">
      Sie benötigen mehr Informationen?
    </h2>

    <p
      class="font-body font-thin text-center"
      v-if="category.id >= 0 && type.id >= 0"
    >
      Um weitere Informationen
      <span v-if="category.id >= 0 && category"
        >zu <span class="text-orange-500">{{ category.name }}</span> <br
      /></span>
      <span v-if="type.id >= 0 && type">
        vom Typ <span class="text-orange-500">{{ type.name }}</span>
      </span>
      zu erhalten fragen Sie bitte direkt bei uns an.
    </p>
    <p class="font-body font-thin text-center" v-else>
      Um weitere Informationen zu erhalten fragen Sie bitte direkt bei uns an.
    </p>
    <div>
      <a
        :href="
          'mailto:info@benedict-and-riva.com?subject=Produktanfrage&body=' +
            category.name +
            ': ' +
            type.name
        "
        class="mt-4 block py-4 px-6 text-center w-full md:w-96 uppercase text-sm font-display text-white bg-orange-500 pointer w-auto"
      >
        Kontakt aufnehmen
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    category: {
      type: Object
    },
    type: {
      type: Object
    }
  }
}
</script>
