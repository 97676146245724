<template>
  <div class="mb-3">
    <div class="w-full">
      <div class="space-y-1">
        <Listbox v-model="active">
          <ListboxLabel
            class="block leading-tight text-black font-display font-semibold text-sm uppercase mb-2"
          >
            <span v-if="type == 'types'">{{ active.typeName }}</span>
            <span v-else>{{ data.label }}</span>
          </ListboxLabel>

          <div class="relative">
            <span class="inline-block w-full">
              <ListboxButton
                class="relative w-full py-3 pl-3 pr-10 text-left transition duration-150 ease-in-out bg-white border border-gray-300 cursor-default focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              >
                <span class="block truncate font-body font-thin text">
                  {{ active.name }}
                </span>
                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                  <svg
                    class="w-5 h-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </ListboxButton>
            </span>

            <div class="absolute w-full mt-1 bg-white shadow-lg z-50">
              <ListboxOptions
                class="py-1 overflow-auto text-base leading-6 shadow-xs max-h-60 focus:outline-none sm:text-sm sm:leading-5"
              >
                <ListboxOption
                  v-for="option in options"
                  :key="option.id"
                  :value="option"
                  :className="resolveListboxOptionClassName"
                  v-slot="{ active, selected }"
                >
                  <span
                    :class="
                      classNames(
                        'block truncate font-body',
                        selected ? 'font-medium' : 'font-thin'
                      )
                    "
                  >
                    {{ option.name }}
                  </span>
                  <span
                    v-if="selected"
                    :class="
                      classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-orange-500'
                      )
                    "
                  >
                    <svg
                      class="w-5 h-5"
                      viewbox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </ListboxOption>
              </ListboxOptions>
            </div>
          </div>
        </Listbox>
      </div>
    </div>
  </div>
</template>

<script>
//import { defineComponent, h, ref, onMounted, watchEffect, watch } from 'vue'
import { reactive, ref } from 'vue'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default {
  data() {
    return {}
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  setup(props) {
    // const data = reactive(props.data)
    const type = reactive(props.type)
    const options = reactive(props.data.options)
    var index = 0
    var active

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    var selected = urlParams.get(type)

    if (selected) {
      index = options.map(e => e.id).indexOf(selected)
    }
    active = ref(options[index])

    console.log(active)
    return {
      options,
      active,
      classNames,
      resolveListboxOptionClassName({ active }) {
        return classNames(
          'relative py-2 pl-3 cursor-default select-none pr-9 focus:outline-none',
          active ? 'text-white bg-orange-500' : 'text-gray-900'
        )
      }
    }
  },

  mounted() {
    const string = this.type + ':' + this.active.id

    if (this.type == 'categories') {
      this.$emit('category-name', this.active.name)
      this.$emit('filter-category', string)
    } else {
      if (this.type == 'types') {
        this.$emit('type-name', this.active.name)
      }
      this.$emit('filter', string)
    }
  },
  methods: {},
  watch: {
    active: function(val) {
      const string = this.type + ':' + val.id

      if (this.type == 'categories') {
        this.$emit('category', this.active)
        this.$emit('filter-category', string)
      } else {
        if (this.type == 'types') {
          this.$emit('type', this.active)
        }
        this.$emit('filter', string)
      }
    }
  }
}
</script>
