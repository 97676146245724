<template>
  <div
    class="flex flex-col items-start justify-between py-3 border-b border-gray-200 md:flex-row md:items-center"
  >
    <div class="w-full text-lg uppercase truncate font-body font-regular">
      {{ download.filename }}
    </div>
    <div class="inline-flex items-center mt-2 md:mt-0">
      <div class="mr-3 font-thin font-body">{{ download.type }}</div>
      <div
        class="mr-3 uppercase font-body font-thin text-sm border border-black rounded-full py-0.5 px-4"
      >
        {{ download.filetype }}
      </div>
      <div class="block mr-3 font-thin uppercase font-body">
        {{ download.size }}MB
      </div>
      <a
        :href="download.url"
        target="_blank"
        @click="gtag('Download-Datei', 'Download-Datei')"
        class="flex items-center gap-2 mr-3"
        download
      >
        <span
          class="hidden text-sm font-medium uppercase font-display xl:inline"
          >download</span
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20.381 20"
          class="inline w-4 h-4 text-orange-500 transform rotate-45"
        >
          <g fill="none" stroke-width="1.5">
            <g stroke="currentColor">
              <path d="M1.115 10.018h18.337M11.65 2l8.022 8.022-8.022 8.022" />
            </g>
            <path d="M0 0h20v20H0z" />
          </g>
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      allProps: []
    }
  },
  props: {
    type: {
      type: String,
      default: 'HIER'
    },
    query: {
      type: String,
      default: ''
    },
    download: {
      type: Object,
      default: () => {
        return {}
      }
    },
    properties: {
      type: Array,
      default: () => {
        return []
      }
    },
    labels: {
      type: Object
    }
  },
  mounted() {},
  methods: {
    getPropName(id) {
      var result = this.properties.find(obj => {
        return obj.id === id
      })

      return result
    },
    gtag(cat, label) {
      this.$gtag.event('Click', {
        'event_category' : cat,
        'event_label' : label
      })
    }
  }
}
</script>
