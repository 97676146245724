<template>
  <div class="block w-2/5 2xl:w-1/4 bg-gray-100 py-4 px-5">
    <div class="">
      <!-- sticky top-18 -->
      <h1 class="uppercase font-display font-semibold text-2xl">
        {{ labels.title }}
      </h1>
      <p class="font-body font-thin text-lg">
        {{ labels.subline }}
      </p>
      <p class="font-body font-thin text-sm mt-2">
        {{ labels.legalnote }}
        <a :href="labels.legalnote_url" class="text-orange-500">{{
          labels.legalnote_label
        }}</a
        >{{ labels.legalnote_label_after }}.
      </p>
    </div>

    <div class="flex flex-col mt-5 sticky top-18">
      <div class="relative hidden">
        <input
          type="text"
          placeholder="Suchen"
          v-model="searchQuery"
          class="relative w-full py-3 pl-3 pr-10 mb-4 text-left transition duration-150 ease-in-out bg-white border border-gray-300 cursor-default focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 focus:border-orange-500"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="absolute w-4 h-4 right-0 top-0 mt-4 mr-3 text-orange-500"
          @click="searchQuery = ''"
          v-if="searchQuery != ''"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <FullFilter
        :all-options="allOptions"
        :selected-options="selectedOptions"
        :labels="labels"
        :search-query="searchQuery"
        @search="search"
        @loadProducts="loadProducts"
        @reloadTypes="reloadTypes"
        @category="setCategory"
      />
    </div>
    <!-- <div v-if="allOptions">
        <div v-for="(options, type) in allOptions" :key="type">
          <OptionSelect
          :type="type"
          :data="options"
          @filter="setFilter"
          @filter-category="reloadTypes"
          @category="setCategory"
          @type="setType"
        />
          v-if="!options.multiple && options.collapsible"
          <OptionSelect
            :type="type"
            :data="options"
            @filter="setFilter"
            @filter-category="reloadTypes"
            @category="setCategory"
          />
        </div>
      </div>
      <button
        @click="reset()"
        class="bg-gray-300 text-white font-display font-medium text-center text uppercase py-3 my-4"
      >
        {{ labels.reset }}
      </button>
    </div> -->
  </div>
</template>
<script>
// import axios from 'axios'
// import { ref } from 'vue'
// import OptionSelect from './ProductFilterOptionSelect.vue'
import FullFilter from './ProductFilterNavigationFull.vue'
export default {
  data() {
    return {
      searchQuery: '',
      fullFilterOpen: false
    }
  },
  props: {
    lang: {
      default: 99,
      type: Number
    },
    allOptions: {
      type: Object
    },
    selectedOptions: {
      type: Object
    },
    labels: {
      type: Object
    }
  },
  components: {
    // OptionSelect
    FullFilter
  },
  mounted() {
    console.log('AA', this.allOptions)
    // axios
    //   .get(
    //     'https://benedict-and-riva.com/?rex-api-call=product_filter_options&lang=' +
    //       this.lang
    //   )
    //   .then(response => {
    //     this.allOptions = response.data.options
    //     this.labels = response.data.labels
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
    // const queryString = window.location.search
    // const urlParams = new URLSearchParams(queryString)
    // console.log(urlParams)
    // // if (urlParams.has('category')) {
    // //   const f = 'categories:' + urlParams.get('category')
    // //   this.reloadTypes(f)
    // // } else {
    // this.loadProducts()
    // // }
  },
  methods: {
    loadProductsByType(type) {
      this.$emit('type', type)
      this.$emit('load-products', { types: type.id })
    },
    setCategory(category) {
      console.log('nav', category)
      this.$emit('category', category)
    },
    loadProducts(options) {
      this.$emit('load-products', options)
    },
    reset() {
      window.location =
        'https://' + window.location.hostname + window.location.pathname
      this.$emit('reset', Date.now())
    },
    openFullFilter() {
      this.fullFilterOpen = true
      console.log('fullFilterOpen')
    },
    closeFullFilter() {
      this.fullFilterOpen = false
      console.log('fullFilterClosed')
    },
    search(queryString) {
      this.$emit('search', queryString)
    },
    reloadTypes(value) {
      this.$emit('reload-types', value)
    }
    // setFilter(value) {
    //   const arr = value.split(':')
    //   const type = arr[0]
    //   const list = arr[1]
    //   this.selectedOptions[type] = list
    //   this.loadProducts()
    // },
    // setCategory(value) {
    //   this.$emit('category', value)
    // },
    // setType(value) {
    //   this.$emit('type', value)
    // },
    // reloadTypes(value) {
    //   const arr = value.split(':')
    //   const type = arr[0]
    //   const list = arr[1]

    //   var typesOptions = ref(this.allOptions.types.options)

    //   // here load new

    //   axios
    //     .get(
    //       'https://benedict-and-riva.com/?rex-api-call=product_filter_options&categories=' +
    //         list +
    //         '&lang=' +
    //         this.lang
    //     )
    //     .then(response => {
    //       //this.allOptions = null
    //       console.log(typesOptions)
    //       console.log(response.data.options.types.options)
    //       //typesOptions = response.data.types.options
    //       this.allOptions.types.options.splice(0)
    //       this.allOptions.types.options.push(
    //         ...response.data.options.types.options
    //       )
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })

    //   this.selectedOptions[type] = list
    //   this.loadProducts()
    // },
    // reset() {
    //   window.location =
    //     'https://' + window.location.hostname + window.location.pathname
    //   this.$emit('reset', Date.now())
    // },
    // buildQueryString() {
    //   var queryList = []

    //   for (var prop in this.selectedOptions) {
    //     const queryString = prop + '=' + this.selectedOptions[prop]
    //     if (this.selectedOptions[prop] != '') {
    //       queryList.push(queryString)
    //     }
    //   }
    //   console.log(this.selectedOptions)
    //   return queryList.join('&')
    // },
    // loadProducts() {
    //   // build query string
    //   const queryString = this.buildQueryString()
    //   this.$emit('filter-query', queryString)
    //   const query =
    //     'https://benedict-and-riva.com/?rex-api-call=product_filter&' +
    //     queryString +
    //     '&lang=' +
    //     this.lang

    //   axios
    //     .get(query)
    //     .then(response => {
    //       this.$emit('labels', response.data.labels)
    //       this.$emit('refresh', response.data.products)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // },
    // searchProducts(queryString) {
    //   const query =
    //     'https://benedict-and-riva.com/?rex-api-call=product_search&search=' +
    //     queryString +
    //     '&lang=' +
    //     this.lang

    //   console.log(query)

    //   axios
    //     .get(query)
    //     .then(response => {
    //       console.log(response.data.products)
    //       this.$emit('labels', response.data.labels)
    //       this.$emit('refresh', response.data.products)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // }
  },
  watch: {
    searchQuery: function(value) {
      console.log(value)
      this.searchProducts(value)
    }
  }
}
</script>
