<template>
  <div>
    <nav class="flex items-center space-x-10">
      <Item
        v-for="(item, count) in rootItems"
        :key="item"
        :data="item"
        :count="count"
        :isOpen="item.open"
        ref="desktopNavRootItem"
        @toggled="toggledChild"
        @toggled2="toggleAll(count)"
      />
    </nav>
  </div>
</template>

<script>
import axios from 'axios'
import Item from './DesktopNavigationRootItem.vue'

export default {
  data() {
    return {
      rootItems: null
    }
  },
  props: {
    lang: {
      default: 99,
      type: Number
    }
  },
  mounted() {
    axios
      .get(
        'https://benedict-and-riva.com/?rex-api-call=navi&type=desktop_navigation&lang=' +
          this.lang
      )
      .then(response => {
        console.log(response.data)
        this.rootItems = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    toggledChild(value) {
      this.$emit('toggled', value)
      console.log(value)
    },
    toggleAll(id) {
      let hasOpen
      let count = 0
      this.rootItems.forEach(element => {
        if (count != id) {
          element.open = false
        }
        hasOpen = false
        count++
      })
      if (this.rootItems[id].open == true) {
        this.rootItems[id].open = false
        hasOpen = false
      } else if (this.rootItems[id].open == false) {
        this.rootItems[id].open = true
        hasOpen = true
      }
      console.log(hasOpen)
      this.$emit('toggled', hasOpen)
    }
  },
  components: {
    Item
  }
}
</script>
