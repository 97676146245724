<template>
  <div class="relative w-full lg:w-2/3 2xl:w-3/4 bg-white pb-5 px-5">
    <div v-if="loading" class="">
      <div
        type="button"
        class="mt-4 bg-gray-50 py-12 w-full mx-auto inline-flex items-center justify-center transition ease-in-out duration-150 font-body font-thin"
        disabled=""
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Produkte werden geladen
      </div>
    </div>
    <div v-else>
      <ProductFilterCard
        v-for="(product, pd) in products"
        :key="pd"
        :product="product"
        :properties="product_props"
        :type="type"
        :query="query"
        :labels="labels"
      />
      <ProductFilterCardEmpty
        v-if="products.length == 0"
        :category="category"
        :type="type"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import ProductFilterCard from './ProductFilterCard'
import ProductFilterCardEmpty from './ProductFilterCardEmpty'
export default {
  components: {
    ProductFilterCard,
    ProductFilterCardEmpty
  },
  data() {
    return {
      product_props: []
    }
  },
  mounted() {
    console.log('results: ' + this.typeName)
    console.log(this.labels)
    axios
      .get('https://benedict-and-riva.com/?rex-api-call=product_filter_props')
      .then(response => {
        this.product_props = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  props: {
    products: {
      type: Array,
      default: () => {
        return []
      }
    },
    labels: {
      type: Object
    },
    category: {
      type: Object
    },
    type: {
      type: Object
    },
    query: {
      type: String
    },
    loading: {
      type: Boolean
    }
  }
}
</script>
