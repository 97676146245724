<template>
  <div class="w-full border-b border-black py-4">
    <div class="w-full flex items-center justify-between w-full">
      <label
        class="leading-tight text-black font-display font-semibold text-sm uppercase"
        :for="type"
      >
        <span v-if="type == 'types'">{{ active.typeName }}</span>
        <span v-else>{{ data.label }}</span>
      </label>
      <input
        type="checkbox"
        :id="type"
        v-model="checked"
        :value="checked"
        class="checkbox border border-gray-400 w-6 h-6 text-orange-500 focus:ring-orange-500"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      checked: false
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {},

  mounted() {},
  methods: {
    toggle() {
      this.open = !this.open
    }
  },
  watch: {
    checked: function() {
      const string = this.type + ':' + this.checked
      this.$emit('filter', string)
    }
  }
}
</script>
