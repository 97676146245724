<template>
  <div class="w-full">
    <!-- src="https://placehold.it/500x500/F3F4F6/FF5A00?text=B%2BR"   -->
    <a :href="product.url + '?' + query">
      <div class="relative">
        <span
          class="absolute px-4 py-2 text-sm font-thin leading-none text-white uppercase rounded-full top-3 right-3 font-body bg-apple-500"
          v-if="product.sustainable == 1"
        >
          {{ labels.sustainable }}
        </span>
        <img
          :src="'https://benedict-and-riva.com/media/' + product.image"
          class="w-full"
          width="300"
          height="300"
        />
      </div>

      <div class="flex flex-col justify-between w-full py-4 md:w-2/3 xl:w-3/4">
        <h2 class="text-3xl font-semibold truncate font-display word-break">
          <a :href="product.url + '?' + query" class="">
            {{ product.name }}
          </a>
        </h2>

        <div class="mt-3.5 inline-flex gap-2 overflow-x-scroll">
          <span
            class="px-4 py-1 text-sm font-thin leading-none uppercase truncate border border-black rounded-full font-body"
          >
            {{ type.id > 0 ? type.name : product.type }}
          </span>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      allProps: []
    }
  },
  props: {
    type: {
      type: Object,
      default: () => {
        return {}
      }
    },
    query: {
      type: String,
      default: ''
    },
    product: {
      type: Object,
      default: () => {
        return {}
      }
    },
    properties: {
      type: Array,
      default: () => {
        return []
      }
    },
    labels: {
      type: Object
    }
  },
  mounted() {},
  methods: {
    getPropName(id) {
      var result = this.properties.find(obj => {
        return obj.id === id
      })

      return result
    }
  }
}
</script>
