<template>
  <div>
    <a
      :href="clang.url"
      v-if="clang"
      class="font-display font-medium uppercase"
      >{{ clang.code }}</a
    >
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      clang: undefined,
    }
  },
  props: {
    article: {},
    lang: {},
  },
  mounted() {
    var query =
      'https://benedict-and-riva.com/?rex-api-call=lang&article=' +
      this.article +
      '&lang=' +
      this.lang

    axios
      .get(query)
      .then((response) => {
        console.log(response.data)
        this.clang = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>
