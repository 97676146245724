<template>
  <div class="w-full border-b border-black py-4">
    <button
      class="w-full flex items-center justify-between w-full "
      @click="toggle()"
    >
      <div
        class="leading-tight text-black font-display font-semibold text-sm uppercase"
      >
        <span v-if="type == 'types'">{{ active.typeName }}</span>
        <span v-else>{{ data.label }}</span>
      </div>
      <svg
        v-if="!open"
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path stroke-width="1" d="M19 9l-7 7-7-7" />
      </svg>
      <svg
        v-if="open"
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8 text-orange-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path stroke-width="1" d="M5 15l7-7 7 7" />
      </svg>
    </button>
    <div
      class="mt-2 flex flex-col space-y-2"
      :class="open ? 'block' : 'hidden'"
    >
      <div v-for="option in data.options" :key="option.id">
        <div v-if="option.id !== ''" class="inline-flex items-center">
          <input
            type="checkbox"
            :id="type + option.id"
            v-model="selectedOptions"
            :value="option.id"
            class="checkbox border border-gray-200 w-6 h-6 text-orange-500 focus:ring-orange-500"
          />
          <label :for="type + option.id" class="ml-4 font-body font-thin">{{
            option.name
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default {
  data() {
    return {
      open: false,
      selectedOptions: []
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {},
  setup(props) {
    // const data = reactive(props.data)
    const type = reactive(props.type)
    const options = reactive(props.data.options)
    var index = 0
    var active

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    console.log('params: ' + urlParams)
    console.log('params: ' + urlParams.get(type))

    var selected = urlParams.get(type)

    if (selected) {
      index = options.map(e => e.id).indexOf(selected)
    }
    active = ref(options[index])

    console.log(active)
    return {
      options,
      active,
      classNames,
      resolveListboxOptionClassName({ active }) {
        return classNames(
          'relative py-2 pl-3 cursor-default select-none pr-9 focus:outline-none',
          active ? 'text-white bg-orange-500' : 'text-gray-900'
        )
      }
    }
  },

  mounted() {
    const string = this.type + ':' + this.active.id

    if (this.type == 'categories') {
      this.$emit('category-name', this.active.name)
      this.$emit('filter-category', string)
    } else {
      if (this.type == 'types') {
        this.$emit('type-name', this.active.name)
      }
      this.$emit('filter', string)
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    }
  },
  watch: {
    selectedOptions: function() {
      const string = this.type + ':' + this.selectedOptions.join()

      this.$emit('filter', string)
    }
  }
}
</script>
