<template>
  <nav class="overflow-y-scroll flex flex-col bg-white">
    <Item
      v-for="(item, count) in rootItems"
      :key="item"
      :data="item"
      :count="count"
      @close-menu="closeMenu"
    />
  </nav>
</template>

<script>
import axios from 'axios'
import Item from './NewMobileNavigationRootItem.vue'
export default {
  data() {
    return {
      rootItems: null
    }
  },
  props: {
    lang: {
      default: 99,
      type: Number
    }
  },
  mounted() {
    axios
      .get(
        'https://benedict-and-riva.com/?rex-api-call=navi_mobile&type=mobile_navigation_lv&lang=' +
          this.lang
      )
      .then(response => {
        console.log(response.data)
        this.rootItems = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  components: {
    Item
  },
  methods: {
    closeMenu() {
      this.$emit('close-menu')
      console.log('new close')
    }
  }
}
</script>
