<template>
  <div class="hidden md:block md:w-1/3 2xl:w-1/4 bg-gray-100 py-4 px-5">
    <div class="">
      <!-- sticky top-18 -->
      <h1 class="uppercase font-display font-semibold text-2xl">
        {{ labels.title }}
      </h1>
      <p class="font-body font-thin text-lg">
        {{ labels.subline }}
      </p>
      <p class="font-body font-thin text-sm mt-2">
        {{ labels.legalnote }}
        <a :href="labels.legalnote_url" class="text-orange-500">{{
          labels.legalnote_label
        }}</a
        >{{ labels.legalnote_label_after }}.
      </p>
    </div>

    <div class="flex flex-col mt-5 sticky top-18">
      <div v-for="(options, type) in allOptions" :key="type">
        <OptionSelect
          :type="type"
          :data="options"
          @filter="setFilter"
          @filter-category="reloadCategories"
          @filter-type="reloadTypes"
          @category="setCategory"
          @type="setType"
        />
      </div>
      <button
        @click="reset()"
        class="bg-gray-300 text-white font-display font-medium text-center text uppercase py-3 my-4"
      >
        {{ labels.reset }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
// import { ref } from 'vue'
import OptionSelect from './DownloadCenterOptionSelect.vue'
export default {
  data() {
    return {
      allOptions: [],
      selectedOptions: [],
      labels: []
    }
  },
  props: {
    lang: {
      default: 99,
      type: Number
    }
  },
  components: {
    OptionSelect
  },
  mounted() {
    axios
      .get(
        'https://benedict-and-riva.com/?rex-api-call=download_center_options&lang=' +
          this.lang
      )
      .then(response => {
        this.allOptions = response.data.options
        this.labels = response.data.labels
      })
      .catch(error => {
        console.log(error)
      })

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    console.log(urlParams)

    // if (urlParams.has('category')) {
    //   const f = 'categories:' + urlParams.get('category')
    //   this.reloadTypes(f)
    // } else {
    this.loadProducts()
    // }
  },
  methods: {
    setFilter(value) {
      const arr = value.split(':')
      const type = arr[0]
      const list = arr[1]
      this.selectedOptions[type] = list
      this.loadProducts()
    },
    reloadCategories(value) {
      const arr = value.split(':')
      const type = arr[0]
      const list = arr[1]

      //  var typesOptions = ref(this.allOptions.products.options)

      // here load new

      axios
        .get(
          'https://benedict-and-riva.com/?rex-api-call=download_center_options&categories=' +
            list +
            '&lang=' +
            this.lang
        )
        .then(response => {
          //this.allOptions = null
          //typesOptions = response.data.types.options

          this.allOptions.types.options.splice(0)
          this.allOptions.types.options.push(
            ...response.data.options.types.options
          )

          this.allOptions.products.options.splice(0)
          this.allOptions.products.options.push(
            ...response.data.options.products.options
          )
        })
        .catch(error => {
          console.log(error)
        })

      this.selectedOptions[type] = list
      this.loadProducts()
    },
    reloadTypes(value) {
      const arr = value.split(':')
      const type = arr[0]
      const list = arr[1]

      // var typesOptions = ref(this.allOptions.products.options)

      // here load new

      var query =
        'https://benedict-and-riva.com/?rex-api-call=download_center_options&types=' +
        list +
        '&lang=' +
        this.lang

      console.log(query)

      axios
        .get(query)
        .then(response => {
          //this.allOptions = null
          // console.log(typesOptions)
          // console.log(response.data.options.products.options)
          //typesOptions = response.data.types.options

          this.allOptions.types.options.splice(0)
          this.allOptions.types.options.push(
            ...response.data.options.types.options
          )

          this.allOptions.products.options.splice(0)
          this.allOptions.products.options.push(
            ...response.data.options.products.options
          )
        })
        .catch(error => {
          console.log(error)
        })

      this.selectedOptions[type] = list

      console.log(this.selectedOptions)

      this.loadProducts()
    },
    reset() {
      window.location =
        'https://' + window.location.hostname + window.location.pathname
      this.$emit('reset', Date.now())
    },
    buildQueryString() {
      var queryList = []

      for (var prop in this.selectedOptions) {
        const queryString = prop + '=' + this.selectedOptions[prop]
        if (this.selectedOptions[prop] != '') {
          queryList.push(queryString)
        }
      }
      return queryList.join('&')
    },
    loadProducts() {
      // build query string
      const queryString = this.buildQueryString()
      this.$emit('filter-query', queryString)
      const query =
        'https://benedict-and-riva.com/?rex-api-call=download_center&' +
        queryString +
        '&lang=' +
        this.lang

      axios
        .get(query)
        .then(response => {
          this.$emit('refresh', response.data.downloads)
        })
        .catch(error => {
          console.log(error)
        })
    },
    setCategory(value) {
      this.$emit('category', value)
    },
    setType(value) {
      this.$emit('type', value)
    }
  }
}
</script>
