<template>
  <div
    class="fixed overflow-scroll pt-20 top-12 inset-0 z-50 w-full block md:hidden bg-white"
  >
    <div class="fixed top-12 inset-x-0 z-50 bg-white">
      <!-- big filter -->
      <div class="flex border-b border-gray-200 divide-x divide-gray-200">
        <div
          class="w-full text-xl font-display font-bold uppercase py-4 px-4 flex items-center"
        >
          <span class="">Filter anzeigen</span>
        </div>
        <button
          class="block border-l border-gray-200 py-4 px-4"
          @click="$emit('full-filter')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-width="1" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
    <div class="py-4 px-5">
      <div v-if="true === false">
        <input
          type="text"
          placeholder="Suchen"
          v-model="localSearchQuery"
          class="relative w-full py-3 pl-3 pr-10 mb-4 text-left transition duration-150 ease-in-out bg-white border border-gray-300 cursor-default focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 focus:border-orange-500"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="absolute w-4 h-4 right-0 top-0 mt-4 mr-3 text-orange-500"
          @click="localSearchQuery = ''"
          v-if="localSearchQuery != ''"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div v-if="allOptions">
        <div v-for="(options, type) in allOptions" :key="type">
          <OptionSelect
            v-if="!options.multiple && options.collapsible"
            :type="type"
            :data="options"
            @filter="setFilter"
            @filter-category="reloadTypes"
            @category="setCategory"
          />
          <OptionMultiselect
            v-if="options.multiple && options.collapsible"
            :type="type"
            :data="options"
            @filter="setFilter"
            @filter-category="reloadTypes"
            @category="setCategory"
          />
          <OptionSingleCheckbox
            v-if="!options.multiple && !options.collapsible"
            :type="type"
            :data="options"
            @filter="setFilter"
          />
        </div>
      </div>
      <div class="mt-8 flex flex-col">
        <button
          @click="applyFilter"
          class="bg-orange-500 text-white font-body text-center text py-3"
        >
          {{ labels.filter }}
        </button>
        <button
          @click="reset()"
          class="mt-2 text-black underline font-body text-center text py-3"
        >
          {{ labels.reset }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import OptionSelect from './MobileProductFilterOptionSelect.vue'
import OptionMultiselect from './MobileProductFilterOptionMultiselect.vue'
import OptionSingleCheckbox from './MobileProductFilterOptionSingleCheckbox.vue'
export default {
  data() {
    return {
      localSearchQuery: '',
      options: []
    }
  },
  props: {
    lang: {
      default: 99,
      type: Number
    },
    allOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    labels: {
      type: Array,
      default: () => {
        return []
      }
    },
    searchQuery: {
      type: String,
      default: ''
    }
  },
  components: {
    OptionSelect,
    OptionMultiselect,
    OptionSingleCheckbox
  },
  methods: {
    applyFilter() {
      console.log('new filter options: ', this.options)

      this.addParamsToLocation(this.options)
      this.$emit('full-filter')
      this.$emit('load-products', this.options)
    },
    setFilter(value) {
      const arr = value.split(':')
      const type = arr[0]
      const list = arr[1]
      this.options[type] = list
      console.log(this.options)
    },
    setCategory(value) {
      console.log(value)
      this.options['categories'] = value.id
      this.$emit('category', value)
    },
    setType(value) {
      console.log(value)
      this.options['type'] = value
    },
    reloadTypes(value) {
      console.log(value)
      this.options['types'] = ''
      this.$emit('reload-types', value)
    },
    reset() {
      window.location =
        'https://' + window.location.hostname + window.location.pathname
      this.$emit('reset', Date.now())
    },
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        window.location.pathname +
          '?' +
          Object.keys(params)
            .map(key => {
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
              )
            })
            .join('&')
      )
    }
  },
  watch: {
    locallocalSearchQuery: function(value) {
      console.log(value)
      this.$emit('search', value)
    }
  }
}
</script>
