<template>
  <li class="list-none">
    <a
      :href="data.url"
      class="font-body font-thin p-6 border-b border-gray-200 w-full text-black inline-block bg-white"
    >
      {{ data.name }}
    </a>
  </li>
</template>
<script>
export default {
  name: 'NewMobileNavigationLinkItem',
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>
