<template>
  <div class="hidden w-full mb-4 bg-gray-100 md:flex">
    <!-- src="https://placehold.it/500x500/F3F4F6/FF5A00?text=B%2BR"   -->
    <div class="w-full md:w-1/3 xl:w-1/4">
      <a :href="product.url + '?' + query">
        <img
          :src="'https://benedict-and-riva.com/media/' + product.image"
          class="object-cover w-full h-full"
          width="350"
          height="350"
        />
      </a>
    </div>

    <div class="flex flex-col justify-between w-full p-4 md:w-2/3 xl:w-3/4">
      <div class="flex items-center justify-between pb-3 border-b border-black">
        <h2 class="text-2xl font-semibold truncate font-display">
          <a :href="product.url + '?' + query" class="">
            {{ product.name }}
          </a>
        </h2>

        <div class="inline-flex gap-2 ml-2">
          <span
            class="px-4 py-1 text-sm font-thin leading-none uppercase truncate border border-black rounded-full font-body"
          >
            {{ type.name || product.type }}
          </span>
          <span
            class="px-4 py-1 text-sm font-thin leading-none text-white uppercase bg-black rounded-full font-body"
            v-if="product.sustainable == 1"
          >
            {{ labels.sustainable }}
          </span>
        </div>
      </div>
      <div class="flex-grow py-4">
        <ul class="gap-10 cols-2">
          <li
            v-for="(prop, pd) in product.specs"
            :key="pd"
            class="inline-flex justify-between w-full mb-3 text-lg font-thin font-body"
          >
            <span class="w-64 font-medium truncate">
              {{ prop.name }}
            </span>
            <span class="w-48 truncate">{{ prop.value }}</span>
          </li>
        </ul>
      </div>
      <div class="flex justify-end mt-2">
        <a
          :href="product.url + '?' + query"
          class="text-sm font-medium uppercase font-display"
        >
          {{ labels.see_more }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.381 20"
            class="text-orange-500 w-4 h-4 inline ml-0.5 -mt-1"
            stroke-width="1.5"
          >
            <g fill="none">
              <g stroke="currentColor">
                <path
                  d="M1.115 10.018h18.337M11.65 2l8.022 8.022-8.022 8.022"
                />
              </g>
              <path d="M0 0h20v20H0z" />
            </g>
          </svg>
        </a>
      </div>
    </div>
    <!--<div>{{ product.name }}</div>-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      allProps: []
    }
  },
  props: {
    type: {
      type: Object,
      default: () => {
        return {}
      }
    },
    query: {
      type: String,
      default: ''
    },
    product: {
      type: Object,
      default: () => {
        return {}
      }
    },
    properties: {
      type: Array,
      default: () => {
        return []
      }
    },
    labels: {
      type: Object
    }
  },
  mounted() {},
  methods: {
    getPropName(id) {
      var result = this.properties.find(obj => {
        return obj.id === id
      })

      return result
    }
  }
}
</script>
