import { createApp } from 'vue'
import VueGtag from 'vue-gtag-next'
import Navigation from './Navigation.vue'
import ProductFilter from './ProductFilter.vue'
import MobileProductFilter from './MobileProductFilter.vue'
import DownloadCenter from './DownloadCenter.vue'
import './styles.css'

createApp(ProductFilter).mount('#productFilter')
createApp(MobileProductFilter).mount('#mobileProductFilter')

const dlApp = createApp(DownloadCenter)
dlApp.use(VueGtag, {
  property: {
    id: "UA-47409890-1"
  }
})
dlApp.mount('#downloadCenter')

const navApp = createApp(Navigation)
navApp.use(VueGtag, {
  property: {
    id: "UA-47409890-1"
  }
})
navApp.mount('#nav')